import React, { useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import Shape from '../../assets/shape.png';

const Contact = () => {
  const form = useRef();
  const [formValues, setFormValues] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });

    // Clear error messages for valid inputs
    if (errors[name]) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formValues.user_name) {
      formErrors.user_name = "Name is required*";
    }
    if (!formValues.user_email) {
      formErrors.user_email = "Email is required*";
    } else if (!/\S+@\S+\.\S+/.test(formValues.user_email)) {
      formErrors.user_email = "Email is invalid";
    }
    if (!formValues.message) {
      formErrors.message = "Message is required*";
    }
    return formErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      emailjs
        .sendForm(
          "service_myportfolio",
          "template_myportfolio",
          form.current,
          "0IxelkMGDl07yxXQw"
        )
        .then(
          (result) => {
            console.log(result.text);
            e.target.reset();
            setFormValues({
              user_name: '',
              user_email: '',
              user_phone: '',
              message: ''
            });
            setErrors({});
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div id="contact-section">
      <img src={Shape} className="shape-img" alt="Shape" />
      <div className="contact-wrapper">
        <div className="contact-container">
          <div className="contact-header" data-aos="fade-down">
            <h1 data-aos="zoom-in">CONTACT</h1>
          </div>
          <div className="contact-content">
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-group">
                {errors.user_name && <span className="error">{errors.user_name}</span>}
                <input
                  type="text"
                  placeholder="Name"
                  id="name"
                  name="user_name"
                  value={formValues.user_name}
                  onChange={handleInputChange}
                  data-aos="zoom-in"
                />
              </div>
              <div className="form-group">
                {errors.user_email && <span className="error">{errors.user_email}</span>}
                <input
                  type="email"
                  placeholder="Email"
                  id="email"
                  name="user_email"
                  value={formValues.user_email}
                  onChange={handleInputChange}
                  data-aos="zoom-in"
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  placeholder="Phone"
                  id="phone"
                  name="user_phone"
                  value={formValues.user_phone}
                  onChange={handleInputChange}
                  data-aos="zoom-in"
                />
              </div>
              <div className="form-group">
                {errors.message && <span className="error">{errors.message}</span>}
                <textarea
                  placeholder="Message"
                  id="message"
                  name="message"
                  value={formValues.message}
                  onChange={handleInputChange}
                  data-aos="zoom-in"
                />
              </div>
              <input type="submit" value="Send" className="contact-button" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
