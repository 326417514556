import React from "react";
import "./Experience.css";
import Shape from '../../assets/shape.png'

const Experience = () => {
  return (
    <div id="experience-section">
      <img src={Shape} className="shape-img"></img>
      <div className="experience-wrapper">
        <div className="experience-container">
          <div className="experience-header" data-aos="fade-down">
            <h1>EXPERIENCE</h1>
          </div>
          <div className="experience-content">
            <div className="experience-bio">
              <div className="bio-header" data-aos="zoom-in">
                <h2>Fullstack Developer - TrioKos</h2>
                <div className="work-date" data-aos="zoom-in">
                  <h4>01/May/2022 - 01/June/2023</h4>
                  <hr className="hr-row-work-date"></hr>
                </div>
              </div>
              {/* <p className="bio-p" data-aos="zoom-in">
                Currently I am working as a React.Js and Solidity Developer for
                TrioKos since 01/May/2022, developing and deploying
                high-security smart contracts on Ethereum Mainnet, as well as
                building front-end interfaces with React JS.
              </p> */}
            </div>
            <div className="experience-responsibilities">
              {/* <div className="responsibilities-header" data-aos="zoom-in">
                <h3>Responsibilities</h3>
                <hr className="hr-row-experience"></hr>
              </div> */}
              <div className="responsibilities-content">
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Developed reusable components and APIs, compiling them into front-end libraries for future use.
                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Built server-side applications and microservices, ensuring optimal performance and scalability.
                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Developed RESTful APIs to facilitate seamless communication between front-end and back-end systems.
                  </p>
                </div>

                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Translate UI/UX design wireframes to actual code.
                  </p>
                </div>

                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Develop and deploy high-security smart contracts on the Ethereum Mainnet using Solidity.
                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Audit Smart Contracts and Debug Smart Contracts to increase
                    security.
                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Using Hardhat to edit, compile, debug and deploy smart
                    contracts and dApps.
                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Using ethers.js and web3.js to interact front-end with
                    Ethereum Blockchain.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-row-big" id="experience-hr"></hr>
          <div className="experience-content">
            <div className="experience-bio">
              <div className="bio-header" data-aos="zoom-in">
                <h2>Software Enginneer - Twig</h2>
                <div className="work-date" data-aos="zoom-in">
                  <h4>05/June/2023 - 01/March/2024</h4>
                  <hr className="hr-row-work-date"></hr>
                </div>
              </div>
              {/* <p className="bio-p" data-aos="zoom-in">
                Currently I am working as a React.Js and Solidity Developer for
                TrioKos since 01/May/2022, developing and deploying
                high-security smart contracts on Ethereum Mainnet, as well as
                building front-end interfaces with React JS.
              </p> */}
            </div>
            <div className="experience-responsibilities">
              {/* <div className="responsibilities-header" data-aos="zoom-in">
                <h3>Responsibilities</h3>
                <hr className="hr-row-experience"></hr>
              </div> */}
              <div className="responsibilities-content">
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Design, develop, and maintain scalable, reusable, and performance-driven backend services using the Nest.js framework.                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Proficient in database design, architecting database schemas, optimizing data structures, and ensuring data integrity.                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Experience with building server-side applications and RESTful APIs.                  </p>
                </div>

                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Familiarity with database technologies, such as MySQL, PostgreSQL and MongoDB.                  </p>
                </div>

                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Developing microservices-based applications using Nest.js.                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Leveraging Nest.js's dependency injection system to structure and organize code.
                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Analyzing and resolving technical issues and bugs.
                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Creating middleware components to handle authentication, validation, and other request/response processing.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-row-big" id="experience-hr"></hr>
          <div className="experience-content">
            <div className="experience-bio">
              <div className="bio-header" data-aos="zoom-in">
                <h2>Software Enginneer - Wingpay</h2>
                <div className="work-date" data-aos="zoom-in">
                  <h4>04/March/2024 - Present</h4>
                  <hr className="hr-row-work-date"></hr>
                </div>
              </div>
              {/* <p className="bio-p" data-aos="zoom-in">
                Currently I am working as a React.Js and Solidity Developer for
                TrioKos since 01/May/2022, developing and deploying
                high-security smart contracts on Ethereum Mainnet, as well as
                building front-end interfaces with React JS.
              </p> */}
            </div>
            <div className="experience-responsibilities">
              {/* <div className="responsibilities-header" data-aos="zoom-in">
                <h3>Responsibilities</h3>
                <hr className="hr-row-experience"></hr>
              </div> */}
              <div className="responsibilities-content">
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Build scalable, reusable, and performance-driven backend services using Node.Js and the NestJS framework.                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Engineer integrations with third-party services, including payment gateways like Stripe.                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Proficient in database design, architecting database schemas, optimizing data structures, and ensuring data integrity.                  </p>
                </div>

                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Work with various database technologies, such as PostgreSQL and MongoDB.                  </p>
                </div>

                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Experience in building server-side applications and RESTful APIs to support front-end functionality.                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Leverage NestJS's dependency injection system to structure and organize code.
                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Analyze and resolve technical issues and bugs.                  </p>
                </div>
                <div className="responsibilities-box">
                  <hr className="responsibilities-hr"></hr>
                  <p data-aos="zoom-in">
                    Create middleware components to handle authentication, validation, and other request/response processing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <hr className="hr-row-big"></hr> */}
      </div>
    </div>
  );
};

export default Experience;
