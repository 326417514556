import React from "react";
import "./Programming.css";
// import Solidity from "../../assets/solidity.png";
import Solidity from "../../assets/code_inspection.svg";
// import Web from "../../assets/web.png";
import Web from "../../assets/code_review.svg";
// import Reactimg from "../../assets/react.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Shape from '../../assets/shapes-white.png'

const Programming = () => {
  return (
    <div id="programming-section">
      <img src={Shape} className="shape-img"></img>
      <div className="programming-wrapper">
        <div className="programming-container">
          <div className="programming-header">
            <h1 data-aos="zoom-in-down">WHAT I DO</h1>
          </div>
          <div className="programming-content">
            <div className="language-fields">
              <img
                src={Solidity}
                alt="/"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
              <p
                className="language-h"
                data-aos="flip-down"
                data-aos-duration="1700"
              >
                BACKEND DEVELOPER
              </p>
              <p
                className="language-p"
                data-aos="flip-up"
                data-aos-duration="1700"
              >
                As a backend developer, I specialize in Node.js and NestJS, architecting scalable solutions and building robust server-side applications, microservices, and RESTful APIs.
              </p>
            </div>
            <div className="language-fields">
              <img
                src={Web}
                alt="/"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              />
              <p
                className="language-h"
                data-aos="flip-down"
                data-aos-duration="1700"
              >
                FULL STACK DEVELOPER
              </p>
              <p
                className="language-p"
                data-aos="flip-up"
                data-aos-duration="1700"
              >
                Combining frontend and backend expertise, I build dynamic web interfaces using React.js and Redux, while ensuring robust backend solutions with Node.js and NestJS.
              </p>
            </div>
          </div>
        </div>
        {/* <hr className="hr-row-big"></hr> */}
      </div>
    </div>
  );
};

export default Programming;
