import React from "react";
import "./About.css";
// import profileimg2 from "../../assets/profile2.png";
import profileimg2 from "../../assets/programming-new.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import Shape from '../../assets/shape.png'

const About = () => {
  AOS.init({
    duration: 1200,
  });

  return (
    <div id="about-section">
      <img src={Shape} className="shape-img"></img>
      <div className="about-wrapper">
        <div className="about-container">
          <div className="about-header" data-aos="fade-down">
            <h1>ABOUT ME</h1>
          </div>
          <div className="about-content">
            <div className="about-p" data-aos="fade-up">
              <hr className="about-hr"></hr>
              <p>
                As a Software Developer, I specialize in Node.js and the NestJS framework, architecting scalable backend solutions to ensure optimal performance. My expertise extends to building server-side applications, microservices, and RESTful APIs, with proficiency in database technologies such as MySQL, PostgreSQL, and MongoDB. In previous roles, I demonstrated strong skills in Node.js, NestJS, and Solidity development. I have actively contributed to the deployment of secure smart contracts on the Ethereum Mainnet, earning recognition for my adaptability, teamwork, and ability to deliver high-quality software solutions under pressure. My technical skill set includes Node.js, NestJS, TypeScript, JavaScript, and Solidity. I am committed to continuous professional development, having completed specialized training and a Full Stack Web Development course. Beyond formal education, I stay current with the latest industry trends and emerging technologies. Dedicated to continuous growth, I am eager to leverage my expertise in innovative projects and contribute to the success of forward-thinking organizations.
              </p>
            </div>
            <div className="about-img" data-aos="zoom-out-down">
              <img src={profileimg2} alt="/" />
            </div>
          </div>
        </div>
        {/* <hr className="hr-row-big" id="services-section"></hr> */}
      </div>
    </div>
  );
};

export default About;
